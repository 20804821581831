<div *ngIf="isYoutube()" class="embed-responsive embed-responsive-16by9">
	<div class="embed-inner embed-responsive-item">
		<iframe width="560" height="315" [src]="videoURI | safe" frameborder="0" 
		allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
		allowfullscreen></iframe>
	</div>

</div>

<video controls *ngIf="isMp4()">
  <source src="{{videoURI}}#t=1" type="video/mp4">
  Ihr Browser unterstützt den HTML5 Video Tag nicht.
</video> 