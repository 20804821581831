<!-- <div #editableDiv class="form-control form-control-{{size}}" [class.empty]="!text" #x [attr.contenteditable]="readonly ? 'false' : 'true'" 
(keydown)="onKeydown($event)"
(paste)="onPaste($event)"
(input)="onInput($event)" 
[attr.placeholder]="placeholder">
</div> -->

<ng-template let-item="item" #mentionListTemplate>

    <div class="row align-items-center">
        <div class="col-auto">
            <sc-avatar size="xs" [user]="item"></sc-avatar>
        </div>
        <div class="col-9 ms-n3">
            {{item.FirstName}} {{item.LastName}}
        </div>
    </div>

</ng-template>

<div class="autosize-container">
	<div class="form-control">
		{{ text }}
	</div>
	<textarea *ngIf="mentionable?.length" class="form-control" 
		[(ngModel)]="text" 
		(ngModelChange)="onTextChange()" 
		[mention]="mentionable" 
		[mentionConfig]="mentionConfig" 
		[mentionListTemplate]="mentionListTemplate"	
		[placeholder]="placeholder">
	</textarea>
	<textarea *ngIf="!mentionable?.length" 
		class="form-control" 
		[(ngModel)]="text" 
		(ngModelChange)="onTextChange()"
		[placeholder]="placeholder">
	</textarea>
</div>