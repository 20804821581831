import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent {

  @Input('video-uri') videoURI: string = '';


  constructor() { }

  ngOnInit() {
  }

  isYoutube()
  {
    if (!this.videoURI) return false;
    return this.videoURI.startsWith('https://www.youtube.com/');
  }

  isMp4()
  {
    if (!this.videoURI) return false;
    return this.videoURI.endsWith('.mp4');
  }

}
