import { Component, Input, OnChanges } from '@angular/core';
import benefits from 'refs/sc-ui/resources/benefits';


@Component({
  selector: 'app-benefit',
  templateUrl: './benefit.component.html',
  styleUrls: ['./benefit.component.scss']
})
export class BenefitComponent implements OnChanges {

  @Input()
  id : any;

  @Input()
  language : 'de' | 'en' = 'de';

  name = '';

  name_en = '';

  svg = '';


  ngOnChanges()
  {
    var benefit = benefits.find(b => this.id && b.id == this.id);

    if (!benefit)
    {
      this.name = 'Keine Angabe';
      this.name_en = 'na';
      this.svg = '';
      return;
    }

    this.name = benefit.name_de;
    this.name_en = benefit.name_en;
    this.svg = benefit.svg;
  }
  

}
