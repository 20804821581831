export default [
	{
		id: 1010,
		name_de: "Betriebsarzt",
		available: false,
		category: "Gesundheit",
		name_en: "Company doctor",
		svg: '<svg xmlns="http://www.w3.org/2000/svg" class="svg-inline--fa fa-w-16" viewBox="0 0 640 512"><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zM448 512L384 304l-64 0 0 58c27.6 7.1 48 32.2 48 62l0 40 0 16-16 0-16 0-16 0 0-32 16 0 0-24c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 24 16 0 0 32-16 0-16 0-16 0 0-16 0-40c0-29.8 20.4-54.9 48-62l0-58-128 0 0 66.3c23.1 6.9 40 28.3 40 53.7c0 30.9-25.1 56-56 56s-56-25.1-56-56c0-25.4 16.9-46.8 40-53.7l0-66.3-64 0L0 512l448 0zM168 424a24 24 0 1 0 -48 0 24 24 0 1 0 48 0zM384 0l0 224 64 0 0 64 96-64 96 0L640 0 384 0z"/></svg>'
	},
	{
		id: 1020,
		name_de: "Massagen",
		available: false,
		category: "Gesundheit",
		name_en: "Massages",
		svg: '<svg xmlns="http://www.w3.org/2000/svg" class="svg-inline--fa fa-w-16" viewBox="0 0 640 512"><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M352 32l0-32L288 0l0 32 0 224-32 0 0-192 0-32-64 0 0 32 0 272c0 1.5 0 3.1 .1 4.6l-43.6-41.5-29-27.6L64.3 329.4l29 27.6 95.5 91c43.1 41.1 100.4 64 160 64l19.2 0c44.4 0 84.9-16.4 115.9-43.5L448 453.1l0-42.2 63.6-27.3L544 308.1 544 128l0-32-64 0 0 32 0 128-32 0 0-192 0-32-64 0 0 32 0 192-32 0 0-224zM288 352l16-32 16 32 32 16-32 16-16 32-16-32-32-16 32-16zM56 120L0 144l56 24 24 56 24-56 56-24-56-24L80 64 56 120zM560 352l-24 56-56 24 56 24 24 56 24-56 56-24-56-24-24-56z"/></svg>'
	},
	{
		id: 1030,
		name_de: "Sportangebote off-site",
		available: false,
		category: "Gesundheit",
		name_en: "Off-site sports",
		svg: '<svg xmlns="http://www.w3.org/2000/svg" class="svg-inline--fa fa-w-16" viewBox="0 0 640 512"><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M192 32L96 32l0 96-64 0 0 96L0 224l0 64 32 0 0 96 64 0 0 96 96 0 0-160 0-32 0-64 0-32 0-160zm32 192l0 64 192 0 0-64-192 0zm224-32l0 32 0 64 0 32 0 160 96 0 0-96 64 0 0-96 32 0 0-64-32 0 0-96-64 0 0-96-96 0 0 160z"/></svg>'
	},
	{
		id: 1040,
		name_de: "Sportangebote on-site",
		available: false,
		category: "Gesundheit",
		name_en: "On-site sports",
		svg: '<svg xmlns="http://www.w3.org/2000/svg" class="svg-inline--fa fa-w-16" viewBox="0 0 640 512"><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M192 32L96 32l0 96-64 0 0 96L0 224l0 64 32 0 0 96 64 0 0 96 96 0 0-160 0-32 0-64 0-32 0-160zm32 192l0 64 192 0 0-64-192 0zm224-32l0 32 0 64 0 32 0 160 96 0 0-96 64 0 0-96 32 0 0-64-32 0 0-96-64 0 0-96-96 0 0 160z"/></svg>'
	},
	{
		id: 1050,
		name_de: "Sport im Team",
		available: false,
		category: "Gesundheit",
		name_en: "Team sports",
		svg: '<svg xmlns="http://www.w3.org/2000/svg" class="svg-inline--fa fa-w-16" viewBox="0 0 512 512"><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M0 256c0 49.6 14.1 96 38.6 135.2L55.8 286l.6-4 2.4-3.2L175.1 126.7 87 63.7C33.7 110.6 0 179.4 0 256zM143 485.8l11.2-114.6 .4-4.4 2.6-3.5L237.6 254l-43.5-99.4L86.7 295.1 65.2 426.7C87 451 113.4 471.2 143 485.8zm30.9 12.8c25.8 8.7 53.4 13.4 82.1 13.4c97.7 0 182.7-54.8 225.8-135.3l-99.7 37.7-3.7 1.4-4-.5L184.5 390.7 173.9 498.6zM499.3 335.9c8.2-25.1 12.7-52 12.7-79.9c0-5.7-.2-11.4-.6-17L406.6 286.6l-4 1.8-4.4-.5-134.8-15-64.3 87.4L374.5 383l124.8-47.2zM374.8 253.1l-68-163.3L203.5 5.4c-32.7 6.8-63.2 19.9-90 37.9l93.7 67 3.6 2.6 1.8 4 54.4 124.2 107.8 12zm132.3-47.3C483.7 88.4 380.2 0 256 0c-2.8 0-5.6 0-8.3 .1l82.5 67.5 3.1 2.5 1.5 3.7 73.6 176.8 98.7-44.8z"/></svg>'
	},
	{
		id: 1060,
		name_de: "Gesundheits­management",
		available: false,
		category: "Gesundheit",
		name_en: "Health management",
		svg: '<svg xmlns="http://www.w3.org/2000/svg" class="svg-inline--fa fa-w-16" viewBox="0 0 512 512"><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M64 288l80 0 16 0 6.2-14.8 18.7-44.9 49.3 109.5 20.7 46.1 22.6-45.2L320 253.7l10.5 21.1 6.6 13.3 14.8 0 96 0s0 0 0 0L256 480 64 288s0 0 0 0zM20.8 240C7.3 218.5 0 193.5 0 167.8C0 92.8 60.8 32 135.8 32c36 0 70.5 14.3 96 39.8L256 96l24.2-24.2c25.5-25.5 60-39.8 96-39.8C451.2 32 512 92.8 512 167.8c0 25.8-7.3 50.8-20.8 72.2l-124.4 0-25.4-50.7L320 146.3l-21.5 42.9-41.4 82.8L205.9 158.2l-22.7-50.5-21.3 51.1L128 240 20.8 240z"/></svg>'
	},
	{
		id: 1061,
		name_de: "Krankenversicherung",
		available: false,
		category: "Gesundheit",
		name_en: "Health insurance",
		svg: '<svg xmlns="http://www.w3.org/2000/svg" class="svg-inline--fa fa-w-16" viewBox="0 0 512 512"><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M64 288l80 0 16 0 6.2-14.8 18.7-44.9 49.3 109.5 20.7 46.1 22.6-45.2L320 253.7l10.5 21.1 6.6 13.3 14.8 0 96 0s0 0 0 0L256 480 64 288s0 0 0 0zM20.8 240C7.3 218.5 0 193.5 0 167.8C0 92.8 60.8 32 135.8 32c36 0 70.5 14.3 96 39.8L256 96l24.2-24.2c25.5-25.5 60-39.8 96-39.8C451.2 32 512 92.8 512 167.8c0 25.8-7.3 50.8-20.8 72.2l-124.4 0-25.4-50.7L320 146.3l-21.5 42.9-41.4 82.8L205.9 158.2l-22.7-50.5-21.3 51.1L128 240 20.8 240z"/></svg>'
	},
	{
		id: 1070,
		name_de: "Ergonomischer Arbeitsplatz",
		available: false,
		category: "Gesundheit",
		name_en: "Ergonomic workplace",
		svg: '<svg xmlns="http://www.w3.org/2000/svg" class="svg-inline--fa fa-w-16" viewBox="0 0 448 512"><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M352 0L96 0l0 256 256 0L352 0zM64 160l0-32L0 128l0 32 0 64 0 32 64 0 0-32 0-64zm384 0l0-32-64 0 0 32 0 64 0 32 64 0 0-32 0-64zM256 384l160 0 0-32-32-64L64 288 32 352l0 32 160 0 0 64-32 0-32 0 0 64 32 0 64 0 64 0 32 0 0-64-32 0-32 0 0-64z"/></svg>'
	},
	{
		id: 2010,
		name_de: "Events für Mitarbeiter:innen",
		available: false,
		category: "Soziales",
		name_en: "Employee events",
		svg: '<svg xmlns="http://www.w3.org/2000/svg" class="svg-inline--fa fa-w-16" viewBox="0 0 640 512"><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M576 254.5c-2.3-.5-4.6-.9-6.9-1.3c-13.2-2.4-25.4-4.1-33.1-5.1c1.1 7.6 2.8 19.9 5.3 33.1c4.3 23.1 10.8 48.9 19.5 57.7c.4 .4 .7 .7 1.1 1.1c17.9 16.8 46.4 16.1 64.2-1.8c18.2-18.3 18.6-47.6 .7-65.6c-7.9-7.9-29.7-13.9-50.8-18zM549 370.5c-3.8-2.6-7.5-5.7-10.9-9.1c-8.7-8.7-13.7-20.5-16.6-28.7c-3.4-9.3-6.2-19.8-8.4-29.6c-4.4-19.7-7.4-40.1-8.8-50.6l-5.9-42 42 5.8 .1 0c7.4 1 19.7 2.7 33.2 5.1C556.8 96.4 449.7 0 320 0S83.2 96.4 66.3 221.5c13.5-2.4 25.8-4.1 33.2-5.1l.1 0 42-5.8-5.9 42c-1.5 10.5-4.4 30.9-8.8 50.6c-2.2 9.8-5 20.3-8.4 29.6c-3 8.2-8 20-16.6 28.7c-3.4 3.4-7.1 6.5-10.9 9.1C133 454.4 219.8 512 320 512s187-57.6 229-141.5zM78 339.8s0 0 0 0c.4-.4 .8-.7 1.1-1.1c8.7-8.8 15.2-34.6 19.5-57.7c2.5-13.2 4.2-25.4 5.3-33.1c0 0 0 0 0 0s0 0 0 0c-21 2.9-77.1 10.7-90.8 24.5c-17.9 18-17.5 47.3 .7 65.6C31.7 356 60.1 356.7 78 339.8zm242.2-8.1c59.1 0 114.1-10.6 160.2-29C464.3 376.7 398.7 432 320.3 432s-144-55.3-160.2-129.2c46.2 18.3 101.2 29 160.2 29zM240 152c28 0 56 40 56 80c0 0-28-32-56-32s-56 32-56 32c0-40 28-80 56-80zm104 80c0-40 28-80 56-80s56 40 56 80c0 0-28-32-56-32s-56 32-56 32z"/></svg>'
	},
	{
		id: 2020,
		name_de: "Internationales Team",
		available: false,
		category: "Soziales",
		name_en: "International team",
		svg: '<svg xmlns="http://www.w3.org/2000/svg" class="svg-inline--fa fa-w-16" viewBox="0 0 512 512"><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M454 320l-22 0-48-48 0-80 32 0 0-32-64 0 0 48-80 0-16 0 0 32-64 0 0-32 80-80 0-16-48 0 0-32 32-32c114.9 0 208 93.1 208 208c0 22.3-3.5 43.8-10 64zm-13.4 32C408.2 414.1 345.4 457.8 272 463.4l0-31.4-64 0-48-48 0-112 144 0 48 48 32 0 32 32 24.6 0zM256 512c8.8 0 17.6-.4 26.2-1.3L256 512zm26.2-1.3C411.3 497.6 512 388.6 512 256C512 114.6 397.4 0 256 0c0 0 0 0 0 0C114.6 0 0 114.6 0 256C0 383.5 93.2 489.3 215.3 508.8c13.3 2.1 26.9 3.2 40.7 3.2zM187.3 123.3l-32 32L144 166.6 121.4 144l11.3-11.3 32-32L176 89.4 198.6 112l-11.3 11.3z"/></svg>'
	},
	{
		id: 2030,
		name_de: "Flache Hierarchien",
		available: false,
		category: "Soziales",
		name_en: "Flat hierarchies",
		svg: '<svg xmlns="http://www.w3.org/2000/svg" class="svg-inline--fa fa-w-16" viewBox="0 0 640 512"><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M32 0L64 0 576 0l32 0 0 32 0 216.4c-17-15.2-39.4-24.4-64-24.4l0-160L96 64l0 160c-24.6 0-47 9.2-64 24.4L32 32 32 0zm0 320a64 64 0 1 1 128 0A64 64 0 1 1 32 320zm224 0a64 64 0 1 1 128 0 64 64 0 1 1 -128 0zm288-64a64 64 0 1 1 0 128 64 64 0 1 1 0-128zM416 512l-192 0 32-96 128 0 32 96zM32 416l128 0 32 96L0 512l32-96zm608 96l-192 0 32-96 128 0 32 96z"/></svg>'
	},
	{
		id: 2040,
		name_de: "Duz-Kultur",
		available: true,
		category: "Soziales",
		name_en: "Duz culture",
		svg: '<svg xmlns="http://www.w3.org/2000/svg" class="svg-inline--fa fa-w-16" viewBox="0 0 640 512"><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M298.8 64L224 64l-96 64L0 128 0 352l156.2 0 73.9 67.4 35.4 32.4 47.7-52.2 22.3 20.5 35.4 32.4 44.8-48.9 35.2 32.2 64.9-70.8-35.4-32.4-134.2-123-96.4 75-13 10.1-9.7-13.4-64-88-8.9-12.3 11.8-9.6L298.8 64zm74.1 124.9L550.2 352l89.8 0 0-224-48 0-76 0-4 0-1.4 0-4.3-3.3L426.6 64l-76.9 0L197.9 186.9l45.3 62.3 131-101.9 12.6-9.8 19.6 25.3-12.6 9.8-20.9 16.2z"/></svg>'
	},
	{
		id: 3010,
		name_de: "Coaching",
		available: false,
		category: "Entfaltung",
		name_en: "Coaching",
		svg: '<svg xmlns="http://www.w3.org/2000/svg" class="svg-inline--fa fa-w-16" viewBox="0 0 640 512"><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M416 176c0 97.2-93.1 176-208 176c-36.2 0-70.3-7.8-99.9-21.6L0 352l37.6-75.1C13.9 248.3 0 213.5 0 176C0 78.8 93.1 0 208 0S416 78.8 416 176zm32 0c0-5.2-.2-10.4-.6-15.5C555.1 167.1 640 243.2 640 336c0 37.5-13.9 72.3-37.6 100.9L640 512 531.9 490.4C502.3 504.2 468.2 512 432 512c-95.6 0-176.2-54.6-200.5-129C348.9 372.9 448 288.3 448 176z"/></svg>'
	},
	{
		id: 3020,
		name_de: "Weiterbildungen",
		available: false,
		category: "Entfaltung",
		name_en: "Advanced training",
		svg: '<svg xmlns="http://www.w3.org/2000/svg" class="svg-inline--fa fa-w-16" viewBox="0 0 512 512"><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M0 144L256 0 512 144l0 48L0 192l0-48zM0 512l0-48 64-48 0-192 64 0 0 192 40 0 0-192 64 0 0 192 48 0 0-192 64 0 0 192 40 0 0-192 64 0 0 192 64 48 0 48L0 512zM256 144a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"/></svg>'
	},
	{
		id: 4010,
		name_de: "Flexible Arbeitszeit",
		available: false,
		category: "Work-Life-Balance",
		name_en: "Flexible working hours",
		svg: '<svg xmlns="http://www.w3.org/2000/svg" class="svg-inline--fa fa-w-16" viewBox="0 0 640 512"><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M224 0a128 128 0 1 1 0 256A128 128 0 1 1 224 0zm96 368c0 59.5 29.5 112.1 74.8 144L0 512 64 304l268 0c-7.7 19.8-12 41.4-12 64zm32 0a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm160-80l-32 0 0 16 0 64 0 16 16 0 48 0 16 0 0-32-16 0-32 0 0-48 0-16z"/></svg>'
	},
	{
		id: 4020,
		name_de: "Homeoffice",
		available: false,
		category: "Work-Life-Balance",
		name_en: "Homeoffice",
		svg: '<svg xmlns="http://www.w3.org/2000/svg" class="svg-inline--fa fa-w-16" viewBox="0 0 640 512"><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M224 0a128 128 0 1 1 0 256A128 128 0 1 1 224 0zm96 368c0 59.5 29.5 112.1 74.8 144L0 512 64 304l268 0c-7.7 19.8-12 41.4-12 64zm32 0a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm160-80l-32 0 0 16 0 64 0 16 16 0 48 0 16 0 0-32-16 0-32 0 0-48 0-16z"/></svg>'
	},
	{
		id: 4025,
		name_de: "Mobiles Arbeiten",
		available: false,
		category: "Work-Life-Balance",
		name_en: "Mobile working",
		svg: '<svg xmlns="http://www.w3.org/2000/svg" class="svg-inline--fa fa-w-16" viewBox="0 0 512 512"><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M454 320l-22 0-48-48 0-80 32 0 0-32-64 0 0 48-80 0-16 0 0 32-64 0 0-32 80-80 0-16-48 0 0-32 32-32c114.9 0 208 93.1 208 208c0 22.3-3.5 43.8-10 64zm-13.4 32C408.2 414.1 345.4 457.8 272 463.4l0-31.4-64 0-48-48 0-112 144 0 48 48 32 0 32 32 24.6 0zM256 512c8.8 0 17.6-.4 26.2-1.3L256 512zm26.2-1.3C411.3 497.6 512 388.6 512 256C512 114.6 397.4 0 256 0c0 0 0 0 0 0C114.6 0 0 114.6 0 256C0 383.5 93.2 489.3 215.3 508.8c13.3 2.1 26.9 3.2 40.7 3.2zM187.3 123.3l-32 32L144 166.6 121.4 144l11.3-11.3 32-32L176 89.4 198.6 112l-11.3 11.3z"/></svg>'
	},
	{
		id: 4026,
		name_de: "28 Urlaubstage",
		available: false,
		category: "Work-Life-Balance",
		name_en: "Paid time off and Holidays",
		svg: '<svg xmlns="http://www.w3.org/2000/svg" class="svg-inline--fa fa-w-16" viewBox="0 0 576 512"><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M346.3 271.8l-60.1-21.9L214 448 32 448 0 448l0 64 32 0 512 0 32 0 0-64-32 0-261.9 0 64.1-176.2zm121.1-.2l-3.3 9.1 101.7 37c20.7-90.4-9.7-183.3-75.2-244.3c2 8 3.2 16.3 3.4 24.8l.2 6c1.8 57-7.3 113.8-26.8 167.4zM462 99.1c-1.1-34.4-22.5-64.8-54.4-77.4c-.9-.4-1.9-.7-2.8-1.1c-33-11.7-69.8-2.4-93.1 23.8l-4 4.5C272.4 88.3 245 134.2 226.8 184l-3.3 9.1L434 269.7l3.3-9.1c18.1-49.8 26.6-102.5 24.9-155.5l-.2-6zM88.4 143.9l105 38.2 3.3-9.1c19.5-53.6 49.1-103 87.1-145.5l4-4.5c6.2-6.9 13.1-13 20.5-18.2C217 7.7 131.5 59.7 88.4 143.9z"/></svg>'
	},
	{
		id: 4027,
		name_de: "29 Urlaubstage",
		available: false,
		category: "Work-Life-Balance",
		name_en: "29 vacation days",
		svg: '<svg xmlns="http://www.w3.org/2000/svg" class="svg-inline--fa fa-w-16" viewBox="0 0 576 512"><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M346.3 271.8l-60.1-21.9L214 448 32 448 0 448l0 64 32 0 512 0 32 0 0-64-32 0-261.9 0 64.1-176.2zm121.1-.2l-3.3 9.1 101.7 37c20.7-90.4-9.7-183.3-75.2-244.3c2 8 3.2 16.3 3.4 24.8l.2 6c1.8 57-7.3 113.8-26.8 167.4zM462 99.1c-1.1-34.4-22.5-64.8-54.4-77.4c-.9-.4-1.9-.7-2.8-1.1c-33-11.7-69.8-2.4-93.1 23.8l-4 4.5C272.4 88.3 245 134.2 226.8 184l-3.3 9.1L434 269.7l3.3-9.1c18.1-49.8 26.6-102.5 24.9-155.5l-.2-6zM88.4 143.9l105 38.2 3.3-9.1c19.5-53.6 49.1-103 87.1-145.5l4-4.5c6.2-6.9 13.1-13 20.5-18.2C217 7.7 131.5 59.7 88.4 143.9z"/></svg>'
	},
	{
		id: 4030,
		name_de: "30 Urlaubstage",
		available: false,
		category: "Work-Life-Balance",
		name_en: "30 vacation days",
		svg: '<svg xmlns="http://www.w3.org/2000/svg" class="svg-inline--fa fa-w-16" viewBox="0 0 576 512"><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M346.3 271.8l-60.1-21.9L214 448 32 448 0 448l0 64 32 0 512 0 32 0 0-64-32 0-261.9 0 64.1-176.2zm121.1-.2l-3.3 9.1 101.7 37c20.7-90.4-9.7-183.3-75.2-244.3c2 8 3.2 16.3 3.4 24.8l.2 6c1.8 57-7.3 113.8-26.8 167.4zM462 99.1c-1.1-34.4-22.5-64.8-54.4-77.4c-.9-.4-1.9-.7-2.8-1.1c-33-11.7-69.8-2.4-93.1 23.8l-4 4.5C272.4 88.3 245 134.2 226.8 184l-3.3 9.1L434 269.7l3.3-9.1c18.1-49.8 26.6-102.5 24.9-155.5l-.2-6zM88.4 143.9l105 38.2 3.3-9.1c19.5-53.6 49.1-103 87.1-145.5l4-4.5c6.2-6.9 13.1-13 20.5-18.2C217 7.7 131.5 59.7 88.4 143.9z"/></svg>'
	},
	{
		id: 4040,
		name_de: "30+ Urlaubstage",
		available: false,
		category: "Work-Life-Balance",
		name_en: "30+ vacation days",
		svg: '<svg xmlns="http://www.w3.org/2000/svg" class="svg-inline--fa fa-w-16" viewBox="0 0 576 512"><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M346.3 271.8l-60.1-21.9L214 448 32 448 0 448l0 64 32 0 512 0 32 0 0-64-32 0-261.9 0 64.1-176.2zm121.1-.2l-3.3 9.1 101.7 37c20.7-90.4-9.7-183.3-75.2-244.3c2 8 3.2 16.3 3.4 24.8l.2 6c1.8 57-7.3 113.8-26.8 167.4zM462 99.1c-1.1-34.4-22.5-64.8-54.4-77.4c-.9-.4-1.9-.7-2.8-1.1c-33-11.7-69.8-2.4-93.1 23.8l-4 4.5C272.4 88.3 245 134.2 226.8 184l-3.3 9.1L434 269.7l3.3-9.1c18.1-49.8 26.6-102.5 24.9-155.5l-.2-6zM88.4 143.9l105 38.2 3.3-9.1c19.5-53.6 49.1-103 87.1-145.5l4-4.5c6.2-6.9 13.1-13 20.5-18.2C217 7.7 131.5 59.7 88.4 143.9z"/></svg>'
	},
	{
		id: 4050,
		name_de: "Sonderurlaub",
		available: false,
		category: "Work-Life-Balance",
		name_en: "Special leave",
		svg: '<svg xmlns="http://www.w3.org/2000/svg" class="svg-inline--fa fa-w-16" viewBox="0 0 512 512"><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M112 96L62.2 96c0-36 38.4-96 128-96c58.4 0 95 25.5 113.5 53.2C323.6 40.6 350.2 32 384 32c89.6 0 128 60 128 96l-64 0L416 96l-32 32-82.5 0c28 79.9 21.2 170.2 9.9 226.1C370.9 365.1 416 417.3 416 480l0 32L32 512l0-32c0-70.7 57.3-128 128-128l64 0c29.7-79.3 29.4-150.9 22.8-194l-98.9 98.9c-25.5-25.5-40.7-95 22.6-158.4c1.3-1.3 2.7-2.7 4.1-3.9L144 64 112 96z"/></svg>'
	},
	{
		id: 4060,
		name_de: "Sabbatical",
		available: false,
		category: "Work-Life-Balance",
		name_en: "Sabbatical",
		svg: '<svg xmlns="http://www.w3.org/2000/svg" class="svg-inline--fa fa-w-16" viewBox="0 0 512 512"><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M112 96L62.2 96c0-36 38.4-96 128-96c58.4 0 95 25.5 113.5 53.2C323.6 40.6 350.2 32 384 32c89.6 0 128 60 128 96l-64 0L416 96l-32 32-82.5 0c28 79.9 21.2 170.2 9.9 226.1C370.9 365.1 416 417.3 416 480l0 32L32 512l0-32c0-70.7 57.3-128 128-128l64 0c29.7-79.3 29.4-150.9 22.8-194l-98.9 98.9c-25.5-25.5-40.7-95 22.6-158.4c1.3-1.3 2.7-2.7 4.1-3.9L144 64 112 96z"/></svg>'
	},
	{
		id: 4070,
		name_de: "Ehrenamtliche Tätigkeiten",
		available: false,
		category: "Work-Life-Balance",
		name_en: "Voluntary work",
		svg: '<svg xmlns="http://www.w3.org/2000/svg" class="svg-inline--fa fa-w-16" viewBox="0 0 640 512"><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M199.2 135.1L224 159.9l96 96 96-96 24.8-24.8c14.9-14.9 23.2-35 23.2-56l0-3.6C464 33.8 430.1-.1 388.4-.1c-23 0-44.7 10.4-59.1 28.4L320 39.9l-9.3-11.6C296.4 10.4 274.6-.1 251.6-.1C209.9-.1 176 33.8 176 75.6l0 3.6c0 21 8.3 41.1 23.2 56zM80 64L0 64l0 192 80 0 0-192zM400 480l160 0 0-128-96 0-64-64 0-64-128 0 0 64-64 64-96 0 0 128 160 0L240 416l160 0L400 480zM640 256l-80 0 0 192 80 0 0-192z"/></svg>'
	},
	{
		id: 4080,
		name_de: "Workation",
		available: false,
		category: "Work-Life-Balance",
		name_en: "Workation",
		svg: '<svg xmlns="http://www.w3.org/2000/svg" class="svg-inline--fa fa-w-16" viewBox="0 0 576 512"><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M0 144L256 0 512 144l0 48L0 192l0-48zM0 512l0-48 64-48 0-192 64 0 0 192 40 0 0-192 64 0 0 192 48 0 0-192 64 0 0 192 40 0 0-192 64 0 0 192 64 48 0 48L0 512zM256 144a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"/></svg>'
	},
	{
		id: 4090,
		name_de: "Elternzeit",
		available: false,
		category: "Work-Life-Balance",
		name_en: "Parental leave",
		svg: '<svg xmlns="http://www.w3.org/2000/svg" class="svg-inline--fa fa-w-16" viewBox="0 0 512 512"><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M256 0a96 96 0 1 0 0 192 96 96 0 1 0 0-192zm16 320l0-64 0-32-32 0-32 0 0 32 0 64-128 0-48 0 0 192 416 0 0-192-48 0-128 0zM192 512l0-64 0-64 128 0 0 64 0 64-128 0z"/></svg>'
	},
	{
		id: 4100,
		name_de: "Mutterschutz",
		available: false,
		category: "Work-Life-Balance",
		name_en: "Maternity protection",
		svg: '<svg xmlns="http://www.w3.org/2000/svg" class="svg-inline--fa fa-w-16" viewBox="0 0 448 512"><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M352 0L96 0l0 256 256 0L352 0zM64 160l0-32L0 128l0 32 0 64 0 32 64 0 0-32 0-64zm384 0l0-32-64 0 0 32 0 64 0 32 64 0 0-32 0-64zM256 384l160 0 0-32-32-64L64 288 32 352l0 32 160 0 0 64-32 0-32 0 0 64 32 0 64 0 64 0 32 0 0-64-32 0-32 0 0-64z"/></svg>'
	},

	{
		id: 4105,
		name_de: "Kita-Zuschuss",
		available: true,
		category: "Work-Life-Balance",
		name_en: "Day care allowance",
		svg: '<svg xmlns="http://www.w3.org/2000/svg" class="svg-inline--fa fa-w-16" viewBox="0 0 384 512"><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M192 0a64 64 0 1 1 0 128A64 64 0 1 1 192 0zM176 384l0 96 0 32-64 0 0-32 0-241.2-8.6-10.3-80-96L2.9 107.9l49.2-41L72.6 91.5 143 176l1 0 96 0 2.3 0 68.9-84.3L331.5 67 381 107.5l-20.3 24.8-78.5 96L272.7 240l-.7 0 0 240 0 32-64 0 0-32 0-96-32 0z"/></svg>'
	},
	{
		id: 4106,
		name_de: "Eltern-Kind-Arbeitsplätze",
		available: false,
		category: "Work-Life-Balance",
		name_en: "Parent-child workplaces",
		svg: '<svg xmlns="http://www.w3.org/2000/svg" class="svg-inline--fa fa-w-16" viewBox="0 0 384 512"><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M192 0a64 64 0 1 1 0 128A64 64 0 1 1 192 0zM176 384l0 96 0 32-64 0 0-32 0-241.2-8.6-10.3-80-96L2.9 107.9l49.2-41L72.6 91.5 143 176l1 0 96 0 2.3 0 68.9-84.3L331.5 67 381 107.5l-20.3 24.8-78.5 96L272.7 240l-.7 0 0 240 0 32-64 0 0-32 0-96-32 0z"/></svg>'
	},
	{
		id: 4107,
		name_de: "Zuschuss zur Kinderbetreuung",
		available: true,
		category: "Work-Life-Balance",
		name_en: "Day care allowance",
		svg: '<svg xmlns="http://www.w3.org/2000/svg" class="svg-inline--fa fa-w-16" viewBox="0 0 384 512"><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M192 0a64 64 0 1 1 0 128A64 64 0 1 1 192 0zM176 384l0 96 0 32-64 0 0-32 0-241.2-8.6-10.3-80-96L2.9 107.9l49.2-41L72.6 91.5 143 176l1 0 96 0 2.3 0 68.9-84.3L331.5 67 381 107.5l-20.3 24.8-78.5 96L272.7 240l-.7 0 0 240 0 32-64 0 0-32 0-96-32 0z"/></svg>'
	},
	{
		id: 4108,
		name_de: "Zuschuss zu Kinderfreizeiten",
		available: true,
		category: "Work-Life-Balance",
		name_en: "Subsidy for children's camps",
		svg: '<svg xmlns="http://www.w3.org/2000/svg" class="svg-inline--fa fa-w-16" viewBox="0 0 384 512"><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M192 0a64 64 0 1 1 0 128A64 64 0 1 1 192 0zM176 384l0 96 0 32-64 0 0-32 0-241.2-8.6-10.3-80-96L2.9 107.9l49.2-41L72.6 91.5 143 176l1 0 96 0 2.3 0 68.9-84.3L331.5 67 381 107.5l-20.3 24.8-78.5 96L272.7 240l-.7 0 0 240 0 32-64 0 0-32 0-96-32 0z"/></svg>'
	},
	{
		id: 4110,
		name_de: "Hund erlaubt",
		available: true,
		category: "Work-Life-Balance",
		name_en: "Dog allowed",
		svg: '<svg xmlns="http://www.w3.org/2000/svg" class="svg-inline--fa fa-w-16" viewBox="0 0 576 512"><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M309.6 158.5L336 0l32 0 24 32 72 0 32 32 80 0 0 48c0 44.2-35.8 80-80 80l-32 0-16 0-21.3 0-5.1 30.5-112-64zM416 256.1L416 512l-96 0 0-147.2c-24 12.3-51.2 19.2-80 19.2s-56-6.9-80-19.2L160 512l-96 0 0-264.9c-29.1-13.6-50.5-41.4-54.8-75.1l-1-7.9-4-31.8 63.5-7.9 4 31.8 1 7.9c2 16 15.6 28 31.8 28l39.5 0 16 0 16 0 127.8 0L416 256.1zM464 80a16 16 0 1 0 -32 0 16 16 0 1 0 32 0z"/></svg>'
	},
	{
		id: 5010,
		name_de: "Firmenwagen",
		available: true,
		category: "Ausstattung",
		name_en: "Company car",
		svg: '<svg xmlns="http://www.w3.org/2000/svg" class="svg-inline--fa fa-w-16" viewBox="0 0 512 512"><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M120 32L97.3 32 89.8 53.4 41.3 192 0 192 0 400l0 80 96 0 0-80 320 0 0 80 96 0 0-80 0-208-41.3 0L422.2 53.4 414.7 32 392 32 120 32zM402.9 192l-293.8 0 33.6-96 226.6 0 33.6 96zM64 288a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm352-32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/></svg>'
	},
	{
		id: 5015,
		name_de: "Car Allowance",
		available: false,
		category: "Ausstattung",
		name_en: "Car Allowance",
		svg: '<svg xmlns="http://www.w3.org/2000/svg" class="svg-inline--fa fa-w-16" viewBox="0 0 512 512"><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M120 32L97.3 32 89.8 53.4 41.3 192 0 192 0 400l0 80 96 0 0-80 320 0 0 80 96 0 0-80 0-208-41.3 0L422.2 53.4 414.7 32 392 32 120 32zM402.9 192l-293.8 0 33.6-96 226.6 0 33.6 96zM64 288a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm352-32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/></svg>'
	},
	{
		id: 5020,
		name_de: "Diensthandy",
		available: false,
		category: "Ausstattung",
		name_en: "Service cell phone",
		svg: '<svg xmlns="http://www.w3.org/2000/svg" class="svg-inline--fa fa-w-16" viewBox="0 0 384 512"><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M16 0L368 0l0 512L16 512 16 0zM224 448a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM304 64L80 64l0 320 224 0 0-320z"/></svg>'
	},
	{
		id: 5030,
		name_de: "Parkplatz",
		available: false,
		category: "Ausstattung",
		name_en: "Parking spot",
		svg: '<svg xmlns="http://www.w3.org/2000/svg" class="svg-inline--fa fa-w-16" viewBox="0 0 448 512"><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M448 32L0 32 0 480l448 0 0-448zM160 128l80 0c53 0 96 43 96 96l0 32-5.5 0c-13.2 37.3-48.7 64-90.5 64l-48 0 0 32 0 32-64 0 0-32 0-32 0-64 0-96 0-32 32 0zm80 128c17.7 0 32-14.3 32-32s-14.3-32-32-32l-48 0 0 64 48 0z"/></svg>'
	},
	{
		id: 5050,
		name_de: "Barrierefrei",
		available: false,
		category: "Ausstattung",
		name_en: "Barrier-free",
		svg: "<svg xmlns='http://www.w3.org/2000/svg' class='svg-inline--fa fa-w-16' viewBox='0 0 448 512'><path d='M320 48a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zM198.2 42.8l16.9 9 120 64 34 18.1L345 164l-48 60 111 0 37.2 0-5.5 36.7-24 160-4.7 31.6-63.3-9.5 4.7-31.6L370.8 288l-64.2 0c8.6 19.6 13.3 41.2 13.3 64c0 88.4-71.6 160-160 160S0 440.4 0 352s71.6-160 160-160c11.1 0 22 1.1 32.4 3.3l54.2-54.2-44.8-23.9-56.1 37.4-26.6 17.8L83.6 119.1l26.6-17.8 72-48 15.9-10.6zM160 448a96 96 0 1 0 0-192 96 96 0 1 0 0 192z'/></svg>"
	},
	{
		id: 5060,
		name_de: "Best-in-class equipment",
		available: false,
		category: "Ausstattung",
		name_en: "Best-in-class equipment",
		svg: "<svg xmlns='http://www.w3.org/2000/svg' class='svg-inline--fa fa-w-16' viewBox='0 0 512 512'><path d='M0 208L256 480 512 208 400 32 112 32 0 208zM376 64L299.6 189 464 208 256 232 48 208l164.4-19L136 64 256 177.7 376 64z'/></svg>"
	},
	{
		id: 5070,
		name_de: "Kantine",
		available: true,
		category: "Ausstattung",
		name_en: "Canteen",
		svg: "<svg xmlns='http://www.w3.org/2000/svg' class='svg-inline--fa fa-w-16' viewBox='0 0 448 512'><path d='M64 160L64 0 32 0 0 160c0 47.6 34.6 87.1 80 94.7L80 480l0 32 64 0 0-32 0-225.3c45.4-7.6 80-47.1 80-94.7L192 0 160 0l0 160-16 0L128 0 96 0 80 160l-16 0zM448 0C416 0 288 32 288 176l0 176 96 0 0 128 0 32 64 0 0-32 0-128 0-112 0-32L448 0z'/></svg>"
	},
	{
		id: 5080,
		name_de: "Kaffeespezialitäten",
		available: false,
		category: "Ausstattung",
		name_en: "Coffee specialties",
		svg: "<svg xmlns='http://www.w3.org/2000/svg' class='svg-inline--fa fa-w-16' viewBox='0 0 448 512'><path d='M400.3 74c1 .9 1.9 1.8 2.9 2.8c74.2 74.2 54.2 214.6-44.8 313.6C271.2 477.5 152 503.5 74 457.8l68.4-124.3L331.2 207.6l3.5-2.4 1.9-3.8L400.3 74zM48.2 438.4c-1.1-1.1-2.2-2.1-3.3-3.2C-29.4 361-9.4 220.6 89.6 121.6C176.9 34.3 296.4 8.4 374.3 54.4L309.9 183.3 121.4 309l-3.3 2.2-1.9 3.4L48.2 438.4z'/></svg>"
	},
	{
		id: 6010,
		name_de: "Essenszulage",
		available: false,
		category: "Zulagen",
		name_en: "Meal allowance",
		svg: "<svg xmlns='http://www.w3.org/2000/svg' class='svg-inline--fa fa-w-16' viewBox='0 0 512 512'><path d='M176 24c0 26.5 10.5 38.7 25.9 56.1l.4 .4C218.8 99 240 122.9 240 168l0 24-48 0 0-24c0-26.5-10.5-38.7-25.9-56.1l-.4-.4C149.2 93 128 69.1 128 24l0-24 48 0 0 24zm128 0c0 26.5 10.5 38.7 25.9 56.1l.4 .4C346.8 99 368 122.9 368 168l0 24-48 0 0-24c0-26.5-10.5-38.7-25.9-56.1l-.4-.4C277.2 93 256 69.1 256 24l0-24 48 0 0 24zM3.6 285.2L0 224l512 0-3.6 61.2C503.5 368.1 454 438.2 384 472.9l0 39.1-256 0 0-39.1C58 438.2 8.5 368.1 3.6 285.2z'/></svg>"
	},
	{
		id: 6020,
		name_de: "Erfolgsbonus",
		available: false,
		category: "Zulagen",
		name_en: "Success bonus",
		svg: "<svg xmlns='http://www.w3.org/2000/svg' class='svg-inline--fa fa-w-16' viewBox='0 0 448 512'><path d='M96 53.7L96 0l48 24 16 8L224 0l64 32 16-8L352 0l0 53.7 0 90.3c0 70.7-57.3 128-128 128s-128-57.3-128-128l0-90.3zM144 128l0 16c0 44.2 35.8 80 80 80s80-35.8 80-80l0-16-160 0zM384 304l64 208L0 512 64 304l320 0z'/></svg>"
	},
	{
		id: 6025,
		name_de: "Jobticket",
		available: true,
		category: "Zulagen",
		name_en: "Job ticket",
		svg: "<svg xmlns='http://www.w3.org/2000/svg' class='svg-inline--fa fa-w-16' viewBox='0 0 448 512'><path d='M448 0L0 0 0 448l66.7 0L25.4 489.4 2.7 512l90.5 0 64-64 133.5 0 64 64 90.5 0-22.6-22.6L381.3 448l66.7 0L448 0zM384 64l0 160L64 224 64 64l320 0zM176 336a48 48 0 1 1 96 0 48 48 0 1 1 -96 0z'/></svg>"
	},
	{
		id: 6030,
		name_de: "Bahnticket",
		available: false,
		category: "Zulagen",
		name_en: "Train ticket",
		svg: "<svg xmlns='http://www.w3.org/2000/svg' class='svg-inline--fa fa-w-16' viewBox='0 0 448 512'><path d='M448 0L0 0 0 448l66.7 0L25.4 489.4 2.7 512l90.5 0 64-64 133.5 0 64 64 90.5 0-22.6-22.6L381.3 448l66.7 0L448 0zM384 64l0 160L64 224 64 64l320 0zM176 336a48 48 0 1 1 96 0 48 48 0 1 1 -96 0z'/></svg>"
	},
	{
		id: 6040,
		name_de: "Bike-Leasing",
		available: true,
		category: "Ausstattung",
		name_en: "Bike leasing",
		svg: "<svg xmlns='http://www.w3.org/2000/svg' class='svg-inline--fa fa-w-16' viewBox='0 0 640 512'><path d='M312 32l-24 0 0 48 24 0 25.7 0 34.6 64-149.4 0-27.4-38-7.2-10L176 96l-56 0L96 96l0 48 24 0 43.7 0 22.1 30.7-26.6 53.1c-10-2.5-20.5-3.8-31.2-3.8C57.3 224 0 281.3 0 352s57.3 128 128 128c65.3 0 119.1-48.9 127-112l49 0 13.7 0 7-11.8 84.8-143.5 21.7 40.1C402.4 276.3 384 312 384 352c0 70.7 57.3 128 128 128s128-57.3 128-128s-57.3-128-128-128c-13.5 0-26.5 2.1-38.7 6L373.1 44.6 366.3 32 352 32l-40 0zM458.6 303.7l32.3 59.7 11.4 21.1 42.2-22.8-11.4-21.1-32.3-59.7c3.6-.6 7.4-.9 11.2-.9c39.8 0 72 32.2 72 72s-32.2 72-72 72s-72-32.2-72-72c0-18.6 7-35.5 18.6-48.3zM128 368l70.2 0c-7.3 32.1-36 56-70.2 56c-39.8 0-72-32.2-72-72s32.2-72 72-72c1.7 0 3.4 .1 5.1 .2l-26.5 53.1L128 368zm38.8-48l50.7-101.3 72.9 101.2-.1 .1-123.5 0zm90.6-128l108.5 0L317 274.8 257.4 192z'/></svg>"
	},
	{
		id: 6050,
		name_de: "Mitarbeiterrabatte",
		available: false,
		category: "Zulagen",
		name_en: "Employee discounts",
		svg: "<svg xmlns='http://www.w3.org/2000/svg' class='svg-inline--fa fa-w-16' viewBox='0 0 512 512'><path d='M256 0c36.8 0 68.8 20.7 84.9 51.1C373.8 41 411 49 437 75s34 63.3 23.9 96.1C491.3 187.2 512 219.2 512 256s-20.7 68.8-51.1 84.9C471 373.8 463 411 437 437s-63.3 34-96.1 23.9C324.8 491.3 292.8 512 256 512s-68.8-20.7-84.9-51.1C138.2 471 101 463 75 437s-34-63.3-23.9-96.1C20.7 324.8 0 292.8 0 256s20.7-68.8 51.1-84.9C41 138.2 49 101 75 75s63.3-34 96.1-23.9C187.2 20.7 219.2 0 256 0zM192 224a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm160 96a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM337 209l17-17L320 158.1l-17 17L175 303l-17 17L192 353.9l17-17L337 209z'/></svg>"
	},
	{
		id: 6060,
		name_de: "Mitarbeiterbeteiligung",
		available: false,
		category: "Zulagen",
		name_en: "Employee participation",
		svg: "<svg xmlns='http://www.w3.org/2000/svg' class='svg-inline--fa fa-w-16' viewBox='0 0 640 512'><path d='M160 0l32 0L608 0l32 0 0 32 0 352 0 32-32 0-283.4 0-19.2-64L576 352l0-288L224 64l0 49.1C205.2 102.2 183.3 96 160 96l0-64 0-32zM473 201l-72 72-17 17-17-17-64-64-17-17L320 158.1l17 17 47 47 55-55-39-39 16-16 112 0 0 112-16 16-39-39zM64 224a96 96 0 1 1 192 0A96 96 0 1 1 64 224zM272 352l48 160L0 512 48 352l224 0z'/></svg>"
	},
	{
		id: 6070,
		name_de: "Betriebliche Altersvorsorge",
		available: true,
		category: "Zulagen",
		name_en: "Retirement savings plan",
		svg: "<svg xmlns='http://www.w3.org/2000/svg' class='svg-inline--fa fa-w-16' viewBox='0 0 576 512'><path d='M416 144a144 144 0 1 0 -288 0 144 144 0 1 0 288 0zM248.8 111.4c-.4 .7-.9 1.6-.8 3.6c0 0 0 0 0 .1c0 .4 0 1.9 4.8 4.5c5.6 3 13.5 5.2 23.9 8.2l.2 0c9.3 2.6 21.1 6 30.5 11.5c10.2 6 20 16.1 20.5 32.3c.5 18.2-9.7 30.4-21.7 36.9c-5.8 3.1-12.1 5.1-18.3 6.2l0 10.8 0 16-32 0 0-16 0-11.4c-8.6-1.7-16.7-4.3-23.7-6.6c0 0 0 0 0 0s0 0 0 0c-1.7-.6-3.4-1.1-5-1.6L212 201.2l9.5-30.6 15.3 4.8c2 .6 3.9 1.2 5.8 1.8c11.5 3.6 20.4 6.5 29.9 6.8c6.7 .2 13.8-1.1 18.5-3.6c2.2-1.2 3.4-2.4 4-3.3c.5-.8 1.1-2.1 1-4.5c0-1.4-.2-3-4.7-5.6c-5.3-3.1-12.9-5.4-23.2-8.3l-1.8-.5c-8.9-2.5-19.8-5.6-28.6-10.3c-9.9-5.3-20.6-14.7-21.6-30.7c-1.2-18.8 10-30.9 21.8-37.2c5.7-3.1 12-5.1 18-6.3l0-9.5 0-16 32 0 0 16 0 9.4c6.3 .9 12.3 2.3 17.9 3.7l15.5 3.9-7.7 31.1-15.5-3.9c-9.3-2.3-18.2-4-26.4-4.1c-6.2-.1-13.6 1.3-18.7 4c-2.4 1.3-3.6 2.5-4.2 3.4zM140 327L68.8 384 0 384 0 512l32 0 192 0 160 0 12.4 0 10.2-7 128-88 33-22.7-45.3-65.9-33 22.7-94.5 65L256 416l0-32 32 0 64 0 32 0 0-64-32 0-64 0-64 0-64 0-11.2 0-8.8 7z'/></svg>"
	},
	{
		id: 6075,
		name_de: "Vermögenswirksame Leistungen",
		available: false,
		category: "Zulagen",
		name_en: "capital accumulation benefits",
		svg: "<svg xmlns='http://www.w3.org/2000/svg' class='svg-inline--fa fa-w-16' viewBox='0 0 512 512'><path d='M256 0c36.8 0 68.8 20.7 84.9 51.1C373.8 41 411 49 437 75s34 63.3 23.9 96.1C491.3 187.2 512 219.2 512 256s-20.7 68.8-51.1 84.9C471 373.8 463 411 437 437s-63.3 34-96.1 23.9C324.8 491.3 292.8 512 256 512s-68.8-20.7-84.9-51.1C138.2 471 101 463 75 437s-34-63.3-23.9-96.1C20.7 324.8 0 292.8 0 256s20.7-68.8 51.1-84.9C41 138.2 49 101 75 75s63.3-34 96.1-23.9C187.2 20.7 219.2 0 256 0zM192 224a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm160 96a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM337 209l17-17L320 158.1l-17 17L175 303l-17 17L192 353.9l17-17L337 209z'/></svg>"
	},
	{
		id: 6080,
		name_de: "Weihnachtsgeld",
		available: true,
		category: "Zulagen",
		name_en: "Christmas bonus",
		svg: "<svg xmlns='http://www.w3.org/2000/svg' class='svg-inline--fa fa-w-16' viewBox='0 0 640 512'><path d='M149.7 137.8L64 352l384 0L384 192l81.3 22.2-2.6-13.4-6.3-32.2 32.3-5.6 5.4-.9L434.4 91.6c-32-37.8-79-59.6-128.5-59.6c-68.8 0-130.7 41.9-156.3 105.8zm395.8 23.5L526.9 189l-32.8 5.6 6.4 32.7-19.2 27.2L509 273.1l5.6 32.8 32.7-6.4 27.2 19.2L593.1 291l32.8-5.6-6.4-32.7 19.2-27.2L611 206.9l-5.6-32.8-32.7 6.4-27.2-19.2zM0 384l0 96 48 0 416 0 48 0 0-96-48 0L48 384 0 384z'/></svg>"
	},
	{
		id: 6090,
		name_de: "Urlaubsgeld",
		available: true,
		category: "Zulagen",
		name_en: "Vacation pay",
		svg: "<svg xmlns='http://www.w3.org/2000/svg' class='svg-inline--fa fa-w-16' viewBox='0 0 576 512'><path d='M432 240c53 0 96-43 96-96s-43-96-96-96c-35.5 0-66.6 19.3-83.2 48l-52.6 0C316 40.1 369.3 0 432 0c79.5 0 144 64.5 144 144s-64.5 144-144 144c-28.2 0-54.5-8.1-76.7-22.1l35.3-35.3c12.5 6 26.6 9.4 41.4 9.4zM0 160l0-32 416 0 0 32L232 344l0 120 88 0 0 48-88 0-24 0L96 512l0-48 88 0 0-120L0 160z'/></svg>"
	},
	{
		id: 6091,
		name_de: "Workation",
		available: true,
		category: "Work-Life Balance",
		name_en: "Workation",
		svg: "<svg xmlns='http://www.w3.org/2000/svg' class='svg-inline--fa fa-w-16' viewBox='0 0 512 512'><path d='M256 512c141.4 0 256-114.6 256-256c0-31.8-5.8-62.2-16.4-90.3l.4-1.3 0-4.5 0-32 0-32-32 0-8.1 0C408.9 37.5 336.8 0 256 0S103.1 37.5 56.1 96L48 96 16 96l0 32 0 32 0 4.5 .4 1.3C5.8 193.8 0 224.2 0 256C0 397.4 114.6 512 256 512zM208 272L80 272 48 160l0-32 192 0 0 16 32 0 0-16 192 0 0 32L432 272l-128 0-27.4-96-41.1 0L208 272zm-78.3 71.2l28.6-14.3c8.7 17.5 41.3 55.2 97.7 55.2s88.9-37.7 97.7-55.2l28.6 14.3C369.7 368.3 327.6 416 256 416s-113.7-47.7-126.3-72.8z'/></svg>"
	}
]



