<div class="row align-items-end">
    <div class="col">
        <h3 class="mb-5" *ngIf="headline">{{ headline }}</h3>
    </div>
</div>

<div *ngIf="!readOnly">
    <div class="form-group mb-5">

        <div class="row">

            <div class="col-auto me-n2" *ngIf="user">
                <sc-avatar size="sm" [user]="user"></sc-avatar>
            </div>

            <div class="col">
                <autosize-textarea [(text)]="input" [placeholder]="inputPlaceholder" [mentionable]="mentionItems"></autosize-textarea>
            </div>

            <div *ngIf="input.length > 0" class="col-12 d-flex justify-content-end mt-3">
                <button class="btn btn-primary btn-sm" [disabled]="isSending" (click)="submit()">Kommentieren</button>
            </div>

        </div>

    </div>

    <hr class="mb-5">
</div>

<div class="feed" [class.condensed]="condensed" *ngIf="activities?.length > 0">

    <div [className]="condensed ? 'mb-3' : 'mb-4'" *ngFor="let activity of activities">
        <ng-template [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{'activity':activity}"></ng-template>
    </div>
    
</div>

<div class="feed" *ngIf="activities?.length == 0">
    <p class="mb-0">
        Es gibt noch keine Aktivitäten oder Kommentare
    </p>
</div>

<div class="row d-flex justify-content-center mt-5" *ngIf="hasMoreItems && canLoadMore">
    <div class="col-auto">
        <a class="btn btn-secondary btn-sm" [class.loading]="isLoading" (click)="loadItems()">Weitere laden</a>
    </div>
</div>