<div class="history-loader mt-3" *ngIf="showLoader">
  <sc-chatbot-messageloader></sc-chatbot-messageloader>
</div>

<div class="messages-container">
  <sc-chatbot-message-scroll-container (scrolledToOldest)="loadOlderMessages()" (scrolledToNewest)="onScrolledToNewest()">

    <ng-container *ngFor="let message of messages; let i = index">

      <div *ngIf="message.Index == 1" class="text-center mt-2 smaller text-muted">
        Beginn des Chats
      </div>

      <div *ngIf="message.Type == 'Custom'">
        <ng-container *ngTemplateOutlet="customAnswerTemplate; context: { message: message, sendMessage: sendMessage.bind(this) }" ></ng-container>
      </div>

      <sc-chatbot-answer [isOwnMessage]="message.SenderId == chat.UserId" [canQuickReply]="i == messages.length - 1" [message]="message" (sendMessage)="sendMessage($event)" (onQuickReply)="sendMessage($event)">
      </sc-chatbot-answer>

      <div *ngIf="message.Type == 'End' && message.Payload?.Data?.Button" class="text-center small mt-3 mb-2 light" small>
        <div [routerLink]="message.Payload.Data.Button.Route" [class]="message.Payload.Data.Button.CssClass">
          {{ message.Payload.Data.Button.Text }}
        </div>
      </div>

      <div *ngIf="message.Type == 'End'" class="text-center small mb-2 smaller text-muted">
        Ende des Chats
      </div>

    </ng-container>

  </sc-chatbot-message-scroll-container>
</div>

<div class="status-container" [class.show]="showStatus">
  <div class="status small d-flex align-items-center">
    <svg class="loader-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#030612" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>
    <span class="status-message oneline">{{ statusMessage }}</span>
  </div>
</div>

<div class="input-container" *ngIf="!isReadOnly && newestMessage?.Type != 'End'">
  <!-- <div class="new-message-button-container" [class.show]="showNewMessageButton" (click)="scrollContainer?.scrollToNewest()">
        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="chevron-down" class="svg-inline--fa fa-chevron-down fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path fill="currentColor" d="M441.9 167.3l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L224 328.2 42.9 147.5c-4.7-4.7-12.3-4.7-17 0L6.1 167.3c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17z"></path>
        </svg>
        <span>
            1
        </span>
    </div> -->
  <sc-chatbot-input [message]="newestMessage" [chat]="chat" (submit)="sendMessage($event)"></sc-chatbot-input>
</div>
